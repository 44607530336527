// Breakpoints
// -bt endings for bootstrap breakpoints
$breakpoints: (
        xs: 320px,
        xs-bt: 420px,
        xs-landscape: 579px,
        sm-bt: 767px,
        sm: 780px,
        md: 980px,
        md-bt: 992px,
        ex-md: 1075px,
        lg: 1200px,
        ex-lg: 1400px,
);

@mixin Edge_v13_v14 {
  @supports (-ms-ime-align: auto) {
    /* Microsoft EdgeV13&14 CSS styles go here */
    @content;
  }
}

@mixin Edge_v13 {
  @supports (-ms-accelerator: true) {
    /* Microsoft EdgeV13 CSS styles go here */
    @content;
  }
}

@mixin IE_v10_v11 {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10 & IE11 CSS styles go here */
    @content;
  }
}

@mixin phone {
  @media only screen and (max-width: map-get($breakpoints, sm-bt)) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: map-get($breakpoints, sm-bt)) and (max-width: map-get($breakpoints, md)) {
    @content;
  }
}

@mixin tablet_desktop {
  @media only screen and (min-width: map-get($breakpoints, sm-bt)) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: map-get($breakpoints, md)) {
    @content;
  }
}

@mixin cdk_handset {
  @media (max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape) {
    @content;
  }
}

@mixin cdk_tablet {
  @media (min-width: 600px) and (max-width: 839.99px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape) {
    @content;
  }
}

@mixin cdk_web {
  @media (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
    @content;
  }
}

@mixin bootstrap_extra_small_size {
  @media only screen and (max-width: map-get($breakpoints, xs-bt)) {
    @content;
  }
}

@mixin bootstrap_small_size {
  @media only screen and (max-width: map-get($breakpoints, sm-bt)) {
    @content;
  }
}

@mixin bootstrap_medium_size {
  @media only screen and (min-width: map-get($breakpoints, md-bt)) {
    @content;
  }
}

@mixin bootstrap_large_size {
  @media only screen and (min-width: map-get($breakpoints, lg)) {
    @content;
  }
}

@mixin bootstrap_extra_large_size {
  @media only screen and (min-width: map-get($breakpoints, ex-lg)) {
    @content;
  }
}

@mixin iPad_Portrait {
  /*iPad Pro 9.7"*/
  /* Portrait */
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin iPad_Landscape {
  /* Ipad Landscape */
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}
