$border_color_1: rgba(0,0,0,.0625);
$row_hover_bg: #f5f5f5;

$bg_color_1: white;
$bg_color_2: #F6F7FA;
$bg_color_3: #e4e9ec;
$bg_color_4: #444963;

$referral_color: #00b894;
$reject_color: #ff7675;

$primary_color: #33A4E2;
$primary_color_2: #1C78FE;
$primary_color_3: rgb(109, 97, 103);

$secondary_color: #145DA0;
$secondary_color_2: #A6A8B4;
$secondary_color_3: #6F717D;

$terciary_color: #5FCAC6;
$terciary_color_2: #81D5D2;
$terciary_color_3: #5FCAC6;

$white: white;
$blue: #1C78FE;
$error-color: #FD798B;

$lights: (
  light: #E8E9ED,
  lighter: #EDEEF4,
  lightest: #F2F6FA
);

$darks: (
  dark: #7A7E97,
  darker: #6D7085,
  darkest: #050507
);

//states colors
$normal-state: #AEB1B7;
$disabled-state: #BBB;
$error-state: $error-color;
$focused-state: $blue;
$hovered-state: #808285;

$error-text-color: $error-color;
$error-border-color: $error-color;

$input-placeholder-color: #888993;
$input-label-color: #6B6B6B;
$input-text-color: #252525;
$input-border-color: $normal-state;
$input-hovered-color: $hovered-state;
$input-focused-color: $focused-state;
$input-error-color: $error-state;
$input-disabled-bg-color: #EFEFED;
$input-disabled-border-color: #d2d4d7;

$verticalColors: (
  auto: #ff8818,
  home: #5ed18c,
  cell: #8d49d0,
  internet: #222c31,
  mortgage: #5ed18c,
);

$success: (
  active: #5DD18B,
  hover: #7FDAA4,
  disabled: #4E9E6F
);

$danger: (
  active: #FC516A,
  hover: #FD768A,
  disabled: #BD4556,
  text: #FD798B,
);

$warning: (
  active: #FDC02F,
  hover: #FECD4C,
  disabled: #BE9226
);

$lights: (
  light: #E8E9ED,
  lighter: #EDEEF4,
  lightest: #F2F6FA
);

$darks: (
  dark: #7A7E97,
  darker: #6D7085,
  darkest: #050507
);

$greys: (
  grey: #939CAB,
  grey1: #6F717D,
  grey2: #A6A8B4,
  grey3: #CCCCCC,
  light: #EAECF2,
  lightest: #F7F7F7,
);

$blues: (
  blue1: #1C78FE,
  blue2: #0051A3,
  blue3: #289CFB,
  blue4: #45ACF3,
);

$greens: (
  active: #58d289,
);