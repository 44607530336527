/* You can add global styles to this file, and also import other style files */
@import "./shared-lib/scss/essentials";
@import './app/shared/styles/colors.scss';
@import './app/shared/styles/animate.scss';
// @import '@angular/material/theming';
@import "@angular/material/prebuilt-themes/indigo-pink.css";

/* Add application styles & imports to this file! */
@import '~jsoneditor/dist/jsoneditor.min.css';

.ace-jsoneditor.ace_editor {
  min-height: 500px;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
// $app-primary: mat-palette($mat-blue);
// $app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
// $app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($app-theme);

mat-form-field {
  width: 100%;
}

.mat-tooltip {
  font-size: 14px;
}

.mat-datepicker-popup,
.mat-menu-panel,
.mat-dialog-container {
  background-color: white;
}

.mat-mdc-table {
  @include phone {
    white-space: nowrap !important;
  }
}

.mdc-data-table__row:last-child .mat-mdc-cell {
  border-bottom: 1px solid rgba(127, 127, 127, 0.12) !important;
}

.mat-elevation-z8 {
  box-shadow: none;
}

// Override specific component styles if needed
.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
  background-color: transparent !important;
}

.mdc-dialog__title::before {
  display: none !important;
}

.mat-mdc-dialog-title {
  padding-top: 16px !important;
}

.full-screen-dialog .mat-mdc-dialog-content {
  max-height: 100vh;
}

.mat-dialog-container {
  min-width: 600px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
}

.mdc-tab:not(.mdc-tab--active) {
  background-color: #edf2f8;
}

.mdc-tab.mdc-tab--active {
  background-color: #cde7ff;
}

html {
  --mdc-typography-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --mdc-typography-body1-font-size: 13px;
  --mdc-typography-button-letter-spacing: -.15px;
}

// mat dialog config
html {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 100vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --mat-tab-header-label-text-size: 13px;
  --mat-tab-header-label-text-tracking: -.15px;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;

  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 40px !important;
}

.mat-mdc-tab-nav-bar {
  --mat-tab-header-inactive-focus-label-text-color: #f68741;
  --mat-tab-header-inactive-hover-label-text-color: #f68741;
}

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f68741;
  --mdc-filled-button-label-text-color: #fff;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: $secondary_color;
  --mdc-radio-disabled-unselected-icon-color: $secondary_color;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: $primary_color;
  --mdc-radio-selected-hover-icon-color: $primary_color;
  --mdc-radio-selected-icon-color: $primary_color;
  --mdc-radio-selected-pressed-icon-color: $primary_color;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: $primary_color;
  --mdc-checkbox-selected-hover-icon-color: $primary_color;
  --mdc-checkbox-selected-icon-color: $primary_color;
  --mdc-checkbox-selected-pressed-icon-color: $primary_color;
  --mdc-checkbox-unselected-focus-icon-color: $secondary_color;
  --mdc-checkbox-unselected-hover-icon-color: $secondary_color;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: $primary_color;
  --mdc-checkbox-selected-hover-state-layer-color: $primary_color;
  --mdc-checkbox-selected-pressed-state-layer-color: $primary_color;
  --mdc-checkbox-unselected-focus-state-layer-color: $secondary_color;
  --mdc-checkbox-unselected-hover-state-layer-color: $secondary_color;
  --mdc-checkbox-unselected-pressed-state-layer-color: $secondary_color;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: $secondary_color;
  --mdc-radio-disabled-unselected-icon-color: $secondary_color;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: $primary_color;
  --mdc-radio-selected-hover-icon-color: $primary_color;
  --mdc-radio-selected-icon-color: $primary_color;
  --mdc-radio-selected-pressed-icon-color: $primary_color;
  --mat-radio-ripple-color: $secondary_color;
  --mat-radio-checked-ripple-color: $primary_color;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: $secondary_color;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


*:not(.scrollable)::-webkit-scrollbar {
  display: none;
}

*:not(.scrollable) {
  overflow: -moz-scrollbars-none;
}

*:not(.scrollable) {
  -ms-overflow-style: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
}

p {
  margin: 0;
}

a {
  cursor: pointer;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

body {
  margin: 0;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px !important;
}

.no-results {
  padding: 8px;
  text-align: center;
}

// .mat-mdc-form-field-subscript-wrapper {
//   display: none;
// }

.mat-mdc-card-title {
  font-size: 14px !important;
  font-weight: bold !important;
}

th.mat-mdc-header-cell {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 11px;
}

th.mat-mdc-header-cell:first-of-type {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  text-align: center;
  padding-left: 16px !important;
}

th.mat-mdc-header-cell:last-of-type {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

tr.mat-mdc-row {
  border-radius: 4px;
  overflow: hidden;
  background-color: #f4f9ff;
}

td.mat-mdc-cell:first-of-type {
  padding-left: 24px;
  padding-right: 0;
  border-left: 1px solid rgb(127 127 127 / 12%);
  border-top-left-radius: 4px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
}

td.mat-mdc-cell:last-of-type {
  padding-right: 24px;
  padding-left: 0;
  border-right: 1px solid rgb(127 127 127 / 12%);
  border-top-right-radius: 4px;
  overflow: hidden;
  border-bottom-right-radius: 4px;
}

td.mat-mdc-cell {
  border-top: 1px solid rgb(127 127 127 / 12%);
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-bottom: 1px solid rgb(127 127 127 / 12%);
}

.mat-mdc-row.clickable:hover {
  background-color: #e2ecf8;
  cursor: pointer;
}

#add-fab {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 99999;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  font-size: 24px;
}

.mat-badge-content {
  width: auto !important;
  min-width: 22px;
  border-radius: 22px !important;
  padding: 0 4px;
}

.mat-mdc-tab-link {
  height: 42px !important;
  font-size: 12px;
  min-width: 82px !important;
}


.list-item {
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(rgb(223, 230, 246), .4);
  border-radius: 4px;
  margin-bottom: 8px;
  transition: background-color .2s;
  cursor: pointer;

  &:hover {
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(rgb(218, 228, 250), .65);
  }

  &:active {
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(rgb(223, 230, 246), .85);
  }

  &:first-child {
    margin-top: 24px;
  }

  .list-item-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: rgb(52, 116, 209);
    color: white;
    padding: 6px 12px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .list-item-body {
    padding: 8px 16px !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .list-item-label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 10px;
  }
}

mat-card-header.red {
  background-color: rgb(223, 57, 29) !important;
  color: white !important;
}

mat-card-header.green {
  background-color: rgb(75, 187, 159) !important;
  color: white !important;
}

mat-card-header.blue {
  background-color: rgb(52, 116, 209) !important;
  color: white !important;
}

.mat-tab-link {
  opacity: 1 !important;
}

.mat-calendar-body-in-preview {
  color: $secondary_color;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: $secondary_color;
}

.mat-calendar-body-disabled {
  color: grey;
}

.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: $secondary_color;
}

.mat-calendar-body-in-range:before {
  background: rgba(0, 155, 119, .2)
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range:before {
  background: rgba(249, 171, 0, .2)
}

.mat-calendar-body-comparison-bridge-start:before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to right, rgba(0, 155, 119, .2) 50%, rgba(249, 171, 0, .2) 50%)
}

.mat-calendar-body-comparison-bridge-end:before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to left, rgba(0, 155, 119, .2) 50%, rgba(249, 171, 0, .2) 50%)
}

.mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #6bb5f1;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range>.mat-calendar-body-selected {
  background: #2196f3;
}

.mat-calendar-body-selected {
  background-color: #2196f3;
  color: #fff
}

.mat-calendar-body-disabled>.mat-calendar-body-selected {
  background-color: #0c61a7;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-calendar-body-cell-content {
  border-color: white;
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #0c61a7;
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #0c61a7;
  }
}

.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range:before {
  background: rgba(255, 64, 129, .2)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range:before {
  background: rgba(249, 171, 0, .2)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start:before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to right, rgba(255, 64, 129, .2) 50%, rgba(249, 171, 0, .2) 50%)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end:before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to left, rgba(255, 64, 129, .2) 50%, rgba(249, 171, 0, .2) 50%)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #6bb5f1;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected {
  background: #0c61a7;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: #fff
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled>.mat-calendar-body-selected {
  background-color: #ff408166;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #ff40814d
}

@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #ff40814d
  }
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range:before {
  background: rgba(244, 67, 54, .2)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range:before {
  background: rgba(249, 171, 0, .2)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start:before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to right, rgba(244, 67, 54, .2) 50%, rgba(249, 171, 0, .2) 50%)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end:before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to left, rgba(244, 67, 54, .2) 50%, rgba(249, 171, 0, .2) 50%)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #6bb5f1;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected {
  background: #0c61a7;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: #fff
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled>.mat-calendar-body-selected {
  background-color: #f4433666;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #f443364d;
}

@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #f443364d;
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f
}

.mat-datepicker-toggle-active {
  color: #6bb5f1;
}

.mat-datepicker-toggle-active.mat-accent {
  color: #6bb5f1;
}

.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: $secondary_color;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin: 0
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%)
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none
}

.mat-calendar {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.mat-calendar-body {
  font-size: 13px
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: $secondary_color;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

form {
  width: 100%;
  margin: auto;
}

form input,
form textarea,
form select {
  color: inherit;
}

form select {
  margin-bottom: 1em;
}

.content-with-filters {
  display: block;
  width: calc(100vw - 80px);
  overflow-x: auto;
  height: calc(100vh - 107px);

  .filters-container {
    // position: relative;
    transition: all .18s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    // transform: translate3d(-420px, 0, 0);
    // display: none;
    * {
      transition: all .18s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      opacity: 0;
    }
    &.show {
      // display: block;
      // transform: translate3d(0, 0, 0);
      // width: 420px;
      * {
        opacity: 1;
      }
    }
  }
  
  .filters-container app-filters-panel {
    position: fixed;
    left: 80px;
    top: 107px;
    z-index: 1;
    width: 420px;
  }

}

.content-with-filters.with-filters-showing {
  display: grid;
  grid-template-columns: 420px 1fr;
}
.content-with-filters:not(.with-filters-showing) {
  .filters-container {
    display: none;
  }
}


.summary-container {

  &:not(.with-chart) {
    display: grid;
    grid-template-columns: repeat(6, [col-start] minmax(60px, 1fr) [col-end]);
    row-gap: 24px;
    column-gap: 24px;
  }

  background-color: #f2f8ff;
  border-bottom: 1px solid #dadada;
  padding: 16px;
  border-radius: 4px;
}

.modal {
  display: block;
  background-color: rgb(147 174 210 / 89%);

  h1,
  h2,
  h3,
  h4 {
    font-family: Gotham;
    font-weight: 300;
    color: inherit;
  }
}

.modal.lg .modal-dialog {
  padding: 40px 22px;
}

.modal.sm .modal-dialog {
  padding: 40px 22px;
  max-width: 600px;
  height: 400px;
}

.modal-dialog {
  border: 0;
  height: 100vh;
  width: 100%;
  margin-top: 0;

  @media screen and (max-width: 865px) {
    padding: 16px;
  }
}

.modal-content {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: white;
  box-shadow: 0 0 10px 2px rgba($secondary_color, 0.2);
  overflow: auto;
  padding-bottom: 16px;
  padding-top: 0;
  @extend .slideInUp;
  animation-duration: .26s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.modal form {
  max-width: 600px;
  margin: auto;
}

.modal-header {
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 6;
  background-color: #253348;
  color: white;
}

.modal-body {
  max-width: 100%;
  overflow-x: auto;
  padding: 16px;
}

@media (min-width: 768px) {
  .modal-body {
    padding: 16px;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: auto;
    margin: auto;
  }
}

.modal .mat-mini-fab.mat-white {
  background-color: white;
  color: #253348;
}

.card {
  background-color: white;
  color: #444963;
  border: 1px solid rgba(black, .0625);
  transition: 0.3s;
  border-radius: 0;
  overflow: hidden;
  margin-bottom: 2em;
  margin-top: 2em;
}

.card-header-container {
  border-bottom: 1px solid $border_color_1;
  padding: 3px;

  @media (min-width: 980px) {
    padding: 2em;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }
}

.card-body-container {
  height: 100%;
  overflow: auto;
  background-color: $bg_color_2;
  padding: 16px;
  border-radius: 4px;
}

.data-list-header {
  padding: .5em 1em;
  border-bottom: 1px solid $border_color_1;
  margin-bottom: 2px;

  .param:not(.sortable) i {
    display: none;
  }

  .param.sortable {

    &:hover {
      cursor: pointer;
      background-color: $border_color_1;
    }
  }

  .param.sortable i {
    opacity: 0;
  }

  .param.sortable.active i {
    opacity: 1;
  }

}

pre {
  width: 100%;
}

.break-space {
  white-space: break-spaces;
}
